import React from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";


export default function Contact() {
    const jacobImageName = 'IMG_3759.jpeg'; // This could be dynamic
    const sarahImageName = 'P1030549.JPG';
    const jacobImagePath = require(`./${jacobImageName}`);
    const sarahImagePath = require(`./${sarahImageName}`);

    return (
        <section className="text-center contact-section content-section" id="contact">
            <Container>
                <Row>
                    <Col lg={12} className="mx-auto">
                        <h2>Contact us</h2>
                        <p>Get in contact with a member of the team for any questions.</p>
                        <Button variant={'primary'} size={'lg'} className={'btn-default'}>equity-audit@lbdscouts.org.uk</Button>
                    </Col>
                    <Col lg={12} className="mx-auto">
                        <Row>
                            <Col className="py-5 px-auto">
                                <Card style={{ maxWidth: '500px'}}>
                                    <Card.Img variant={"top"} src={sarahImagePath}></Card.Img>
                                    <Card.Body>
                                        <Card.Title>Sarah Sexton</Card.Title>
                                        <Card.Text>Project Lead & Equity Analysis Expert</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className="py-5 px-auto">
                                <Card style={{ maxWidth: '500px'}}>
                                    <Card.Img variant={"top"} src={jacobImagePath}></Card.Img>
                                    <Card.Body>
                                        <Card.Title>Jacob Tyler</Card.Title>
                                        <Card.Text>Web Engineer & Project Technical Lead</Card.Text>
                                        <Card.Text>Group Scout Leader - 4th Letchworth</Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card style={{ maxWidth: '500px'}}>
                                    <Card.Body>
                                        <Card.Title>Rob Coldrey</Card.Title>
                                        <Card.Text>District Commissioner</Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card style={{ maxWidth: '500px'}}>
                                    <Card.Body>
                                        <Card.Title>Nick Howell</Card.Title>
                                        <Card.Text>Assistant District Commissioner - Scouts</Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card style={{ maxWidth: '500px'}}>
                                    <Card.Body>
                                        <Card.Title>Ian Hunt</Card.Title>
                                        <Card.Text>Group Scout Leader - 12th Letchworth</Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card style={{ maxWidth: '500px'}}>
                                    <Card.Body>
                                        <Card.Title>Amanda Woolmer</Card.Title>
                                        <Card.Text>Group Scout Leader - 7th Letchworth</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}