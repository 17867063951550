import React from "react";


export default function GreyScaleFooter() {
    return (
        <footer>
            <div className="container text-center">
                <p>Copyright © Letchworth, Baldock &amp; Ashwell Scouts 2024</p>
            </div>
        </footer>
    )
}